import { Fragment, useState, useCallBack } from "react";

function CardV2(props) {
  var product = props.item;
  var productReference = props.item;
  const [color, setSelectedColor] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(product);
  const [quantity, setQuantity] = useState(1);
  const [isAdded, setAdded] = useState(props.existing);

  var unAvailable =
    "object-cover w-full -mt-3 h-96 opacity-100 hover:opacity-50 ease-in-out duration-300 opacity-0";
  var available =
    "object-cover rounded-lg lg:object-cover md:object-cover  w-full -mt-3 h-60  lg:h-96 opacity-100 hover:opacity-50 ease-in-out duration-300";

  const validateItem = (e) => {
    try {
      let existingCart = localStorage.getItem("UserCart");
      var cart = JSON.parse(existingCart);
      const found = cart.find((obj) => {
        return obj._id === e._id;
      });

      let status = found === undefined ? true : false;
      return status;
    } catch (error) {
      return true;
    }
  };

  function addToCart(e) {
    if (validateItem(e) && e.status) {
      var variantItem = e;
      variantItem.quantity = quantity;
      // variantItem.price = quantity * e.price
      variantItem.selectedColor = color;
      props.didAdd(variantItem);
      setAdded(true);
    } else {
      setAdded(false);
    }
  }

  function removeToCart(e) {
    props.didRemove(e);
    setAdded(false);
  }

  let quantityService = (isIncrease) => {
    let oldProduct = product;
    if (isIncrease) {
      let newQuantity = quantity + 1;
      setQuantity(newQuantity);
      oldProduct.quantity = newQuantity;
      // oldProduct.price = props.item.price * newQuantity
      // setCurrentProduct(oldProduct)
    } else {
      if (quantity - 1 >= 1) {
        let newQuantity = quantity - 1;
        oldProduct.quantity = newQuantity;
        // oldProduct.price = props.item.price * newQuantity
        setQuantity(newQuantity);
        // oldProduct.price = oldProduct.price * newQuantity
        // setCurrentProduct(oldProduct)
      }
    }
  };

  let controls = () => {
    try {
      return (
        <>
          <div class="text-2xl font-bold text-red-500 ease-in-out duration-300 ">
            {new Intl.NumberFormat("en-PH", {
              style: "currency",
              currency: "PHP",
            }).format(currentProduct.price * quantity)}
          </div>
          <div class="flex items-center m-2 justify-between mt-4 font-bold  ">
            <button
              className="block lg:hidden md:hidden   rounded-full "
              style={{
                borderWidth: 1,
                backgroundColor: props.storeOptions.colorTheme.buttonColor,
                color: props.storeOptions.colorTheme.textColor,
                borderColor: props.storeOptions.colorTheme.borderColor,
              }}
            >
              <p className="m-2 text-xs " onClick={() => addToCart(product)}>
                Add to cart
              </p>
            </button>
            {/* <button className='hidden lg:block  rounded-full hover:shadow-lg ' style={{borderWidth:1,backgroundColor:props.storeOptions.colorTheme.buttonColor,color:props.storeOptions.colorTheme.textColor,borderColor:props.storeOptions.colorTheme.borderColor}}>
              <p className='m-2 text-sm font-bold ' onClick={()=> addToCart(product)}>Add to cart | <span className='text-red-500'>{ new Intl.NumberFormat('en-PH', { style: 'currency', currency: 'PHP' }).format(currentProduct.price * quantity)}</span></p>
              </button> */}
            <div className="flex  grid-cols-2">
              <a
                href="javascript:void(0);"
                //  onClick={()=> addToCart(product)}
                class="block group"
              >
                {product.status ? (
                  <div>
                    <img
                      width={20}
                      height={20}
                      onClick={() => quantityService(false)}
                      style={{ margin: 5 }}
                      className=" ease-in-out duration-300  "
                      alt={product.title}
                      // src="https://uxwing.com/wp-content/themes/uxwing/download/e-commerce-currency-shopping/cart-icon.svg"
                      src={
                        "https://cdn-icons-png.flaticon.com/512/659/659892.png"
                      }
                    />
                  </div>
                ) : null}
              </a>
              <span className="mt-1">{quantity}</span>
              <a
                href="javascript:void(0);"
                //  onClick={()=> addToCart(product)}
                class="block group"
              >
                {product.status ? (
                  <div>
                    <img
                      width={20}
                      height={20}
                      onClick={() => quantityService(true)}
                      style={{ margin: 5 }}
                      className=" ease-in-out duration-300  "
                      alt={product.title}
                      // src="https://uxwing.com/wp-content/themes/uxwing/download/e-commerce-currency-shopping/cart-icon.svg"
                      src={
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQRymu6sl4j8YwAhAo8Rz0Owww4-jgw4gmmgQahRi5tyya1xVxAglgaUxpBXlBiEMRNFQM&usqp=CAU"
                      }
                    />
                  </div>
                ) : null}
              </a>
            </div>
          </div>
        </>
      );
    } catch (error) {}
  };
  let card2 = () => {
    return (
      <div className="mb-10">
        {product.status ? null : (
          <div class="flex justify-center relative">
            <strong class="relative rounded-full h-6 px-4 text-xs font-extralight leading-6 text-white uppercase bg-red-900">
              {" "}
              Out of stock{" "}
            </strong>
          </div>
        )}

        <section className={available + ` hover:shadow-lg`}>
          <a
            href="javascript:void(0);"
            // onClick={()=> addToCart(product)}
            class="block group"
          >
            <img
              alt={product.title}
              src={product.imgUrl}
              class={available}
              style={{
                opacity: product.status ? 1 : 0.1,
              }}
            />
            <p
              className="text-xs rotate-45 text-white opacity-50 "
              style={{ position: "relative", top: -250, left: 50 }}
            >
              www.mmiligan.com
            </p>

            <img
              src={
                "https://localflowershop.sgp1.digitaloceanspaces.com/product/1663478032712-305571712_104582772412929_4178962722973618832_n.jpeg"
              }
              class={
                "object-cover rounded-md lg:object-cover md:object-cover  w-12 h-12 lg:w-8 ml-24 lg:-mt-72 md:-mt-72 -mt-32  content-start lg:h-8 opacity-80 hover:opacity-50 ease-in-out duration-300"
              }
            />
          </a>
          {/* <div style={{top:10,right:20,color:'red'}} className="fixed z-10">Quickview</div> */}
        </section>
        <h5 class="mt-4 text-sm mb-2 font-bold text-black/90">
          {" "}
          {product.title}{" "}
        </h5>
        <textarea
          class="
        form-control
        block
        w-full

        py-1.5

        text-xs
        font-normal
        text-gray-500
        bg-white bg-clip-padding

        rounded
        transition
        ease-in-out
        hover:border-gray-100 border-solid
        hover:border
        m-0
        hover:shadow-lg
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
          id="exampleFormControlTextarea1"
          rows="1"
          placeholder="Product Details"
          value={product.subtitle}
        ></textarea>
        {product.status ? controls() : null}
      </div>
    );
  };
  return card2();
}

export default CardV2;
